<template>
    <ODataLookup :data-object="dsScope_ProcessTreLkp" :whereClause="computedWhereClause" disableRecent ref="lookupRef">
        <ONodeColumn field="AppName" width="400" groupBy loadFullStructure boldDisplay />
        <OColumn field="Name" width="400" />
        <template #target="scope">
            <slot name="target" :target="scope.target" :open="scope.open"></slot>
        </template>
        <template #toolbarActions>
            <div class="d-flex">
                <div class="form-check ms-auto">
                    <input class="form-check-input" id="showAllCheck" type="checkbox"
                        v-model="showAll" @click="showAllProcesses">
                    <label class="form-check-label" for="showAllCheck">{{ $t("Show All") }}</label>
                </div>
            </div>
        </template>
    </ODataLookup>
</template>

<script setup>
import { ref, defineProps, computed, onMounted } from "vue";
import { getDataObjectById } from 'o365-dataobject';
import { ONodeColumn } from 'o365-nodedata';
import 'o365-nodedata';

const lookupRef = ref();
const showAll = ref(false);

const props = defineProps({
    detailAppId: {
        type: String,
        default: null
    },
    whereClause: {
        type: String,
        default: ''
    },
    dataObject: {
        type: Object,
        default: null
    }
});

const computedWhereClause = computed(() => {
    var clauses = [];
    if(props.whereClause.length > 0){
        clauses.push(props.whereClause);
    }
    if(props.detailAppId != null){
        clauses.push(`DetailApp_ID = '${props.detailAppId}'`)
    }

    return clauses.join(" AND ");
})

const dsScope_ProcessTreLkp = props.dataObject ?? getDataObjectById("dsScope_ProcessTreLkp");
dsScope_ProcessTreLkp.useGroupedRequests = false;
dsScope_ProcessTreLkp.nodeData.addConfiguration({
    type: 'groupBy',
    fieldName: 'AppName'
});
dsScope_ProcessTreLkp.nodeData.loadFullStructure = true;
dsScope_ProcessTreLkp.on('DataLoaded', () => {
    dsScope_ProcessTreLkp.nodeData.expandToLevel(2);
})
dsScope_ProcessTreLkp.nodeData.enable();
 
function showAllProcesses(){
    if(!showAll.value){
        dsScope_ProcessTreLkp.recordSource.whereClause = props.whereClause;
        dsScope_ProcessTreLkp.load();
    } else {
        dsScope_ProcessTreLkp.recordSource.whereClause = computedWhereClause.value;
        dsScope_ProcessTreLkp.load();
    }
    showAll.value = !showAll.value;
}

onMounted(() => {
    dsScope_ProcessTreLkp.recordSource.whereClause = computedWhereClause.value;   
})

</script>